import React, { createContext, useContext, useState } from 'react';

export const ClaimContext = createContext();
export const useClaimContext = () => useContext(ClaimContext);
export const ClaimProvider = ClaimContext.Provider;

export const useClaimDeclaration = () => {
  const [claimId, setClaimId] = useState();
  const [claimType, setClaimType] = useState('DAMAGE');
  const [insuredItems, setInsuredItems] = useState();
  const [selectedDevice, setSelectedDevice] = useState();
  const [deviceCategory, setDeviceCategory] = useState();
  const [hasDeviceModified, setHasDeviceModified] = useState();
  const [policyFiles, setPolicyFiles] = useState([]);
  const [claimFiles, setClaimFiles] = useState([]);

  return {
    claimId,
    setClaimId,
    claimType,
    setClaimType,
    insuredItems,
    setInsuredItems,
    selectedDevice,
    setSelectedDevice: device => {
      setDeviceCategory(device.category);
      setSelectedDevice(device);
    },
    deviceCategory,
    setDeviceCategory,
    hasDeviceModified,
    setHasDeviceModified,
    policyFiles,
    setPolicyFiles,
    claimFiles,
    setClaimFiles,
  };
};
export const ClaimDeclarationProvider = ({ children, value }) => (
  <ClaimProvider value={value}>{children}</ClaimProvider>
);
