import { useApi } from 'services/providers/api/Api';
import { customerOverviewMapper } from 'services/providers/api/mappers';
import { useAppState } from 'services/providers/AppState';
import { CustomError } from 'modules/CustomError';

const customError = {
  response: {
    status: 404,
  },
};

const partners = {
  BACKMARKET_UK: 'BMUK',
  BACKMARKET_JP: 'BMJP',
  BACKMARKET: 'BM',
  GALAXUS: 'DI',
  REFURBED: 'RF',
  REFURBED_AT: 'RFAT',
  SALT: 'SA',
  FNAC: 'FN',
  MICROSPOT_CH: 'MI',
  DIGITEC: 'DICH',
  YALLO: 'YA',
  MONACO: 'MO',
  REFURBED_EW: 'RFWE',
  OUTPOST24: 'OP',
};

const useCustomerData = () => {
  const api = useApi();
  const { partner } = useAppState();
  const queryCustomer = async customerId => {
    try {
      let customerData = {};
      const query = api.getCustomerOverview(customerId);
      const resp = await query();
      const { partner: partnerC, ...customer } = customerOverviewMapper(resp);
      if (partner !== partners[partnerC]) {
        throw new CustomError(customError);
      }
      customerData = {
        partner: partnerC,
        ...customer,
      };
      const queryCustomerCare = api.getCustomerCare(partnerC);
      const customerCare = await queryCustomerCare();
      const supportEmail =
        customerCare.data.partner.data.attributes.supportEmail;
      customerData = { ...customerData, supportEmail };
      return { customerData };
    } catch (error) {
      return { error };
    }
  };
  return queryCustomer;
};

export default useCustomerData;
