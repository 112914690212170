import capacityToNumber from './capacityToNumber';
import contextKeys from './contextKeys';
import reduceFormToContextData from './reduceFormToContextData';

function formatFormData(
  { newData, formData },
  { label },
  {
    insuredItems,
    hasDeviceModified,
    claimType,
    policyFiles: files,
    claimFiles,
  },
  partner,
) {
  switch (label) {
    case 'consent':
      return {
        ...newData,
        claimType,
      };
    case 'device':
      const policyFiles = {
        'proof-of-purchase': files,
      };
      const insuredItem = insuredItems[newData.device];
      const insuredData = hasDeviceModified
        ? {
            policyFiles,
            insuredItem: {
              id: insuredItem.id,
              serialNumber: newData.serialNumber,
            },
            otherItem: {
              ...formDevice(insuredItem),
              ...newData,
              capacity: capacityToNumber(newData.capacity),
            },
          }
        : {
            policyFiles,
            insuredItem: {
              id: insuredItem.id,
              ...newData,
              capacity: capacityToNumber(newData.capacity),
              category: insuredItem.category,
              subcategory: insuredItem.subcategory,
            },
          };
      const { insuredItem: noInsu, otherItem: noOther, ...restData } = formData;
      return {
        ...restData,
        ...insuredData,
      };
    case 'details':
      const data = reduceFormToContextData(newData, contextKeys);
      return {
        claimFiles,
        ...formData,
        ...data,
      };
    case 'regulation':
      const additionalData =
        partner === 'BMJP'
          ? {
              phoneticFirstName: newData.phoneticFirstName,
              phoneticLastName: newData.phoneticLastName,
              accountSavingType: newData.accountSavingType,
              phoneticAccountHolder: newData.phoneticAccountHolder,
              reimbursement: newData.request_reimbursement,
            }
          : undefined;
      const bankAccountDetails =
        partner === 'BMJP'
          ? {
              holderName: newData.accountHolder,
              holderName: newData.accountHolder,
              iban: newData.bankIban,
              // bic: newData.bankBic,
              bankName: newData.bankName,
              bankType: newData.bankType,
              branchName: newData.branchName,
              branchType: newData.branchType,
              bankCode: newData.bankCode,
              branchCode: newData.branchCode,
              relationship: newData.relationship,
            }
          : undefined;
      return {
        channel: 'online',
        ...formData,
        deliveryAddress: {
          ...newData,
        },
        additionalData,
        bankAccountDetails,
      };
    default:
      return { ...formData, ...newData };
  }
}

const formDevice = device => ({
  // NOTE NOT IN FORM
  category: device.category,
  customerPrice: device.customerPrice,
  originalPrice: device.originalPrice,
  purchasePrice: device.purchasePrice,
  purchaseDate: device.purchaseDate,
  subcategory: device.subcategory,
  subsidy: device.subsidy,
  grade: device.grade,
  // CAN BE IN THE FORM
  model: device.model,
  manufacturer: device.manufacturer,
});

export default formatFormData;
