import { Form, Layout } from 'antd';
import Stepper, { Actions } from 'components/base/stepper/Stepper';
import Spinner from 'components/shared/Spinner';
import { capitalize } from 'helpers/strings';
import {
  ClaimDeclarationProvider,
  useClaimDeclaration,
} from 'providers/ClaimDeclaration';
import { useStepper } from 'providers/Stepper';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { getInsuredItemsMapper } from 'services/providers/api/mappers';
import useCall from 'hooks/fetch/useInitialCall';
import { useUser } from 'providers/user/User';
import claimSteps from './assets/claim_steps';
import getInitialValues from './assets/values';
import formatFormData from './logic/formatFormData';
import onFinishForm from './logic/onFinishForm';
import * as StepViews from './steps';
import useFormatMsg from 'hooks/translations/useFormatMsg';
import { useApi } from 'services/providers/api/Api';
import DeliveryAddressModalAccept from 'components/containers/policies/useCases/phoneCaseOrder/DeliveryAddressModalAccept';
import ModalConsent from 'components/containers/claims/useCases/declaration/steps/consent/ModalConsent';
import { useAppState } from 'services/providers/AppState';

function ClaimDeclaration() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { indexStep, prevStep, nextStep } = useStepper();
  const [formData, setFormData] = useState({});
  const [initialValues, setInitialValues] = useState();
  const [isShowingModalConsent, setIsShowingModalConsent] = useState(false);
  const { partner } = useAppState();
  const history = useHistory();
  const { policyId } = useParams();
  const [form] = Form.useForm();
  const { user } = useUser();
  const { setInsuredItems, ...claimContext } = useClaimDeclaration();
  const currentStep = claimSteps[indexStep];
  const CurrentView = StepViews[capitalize(currentStep.label)] || null;
  const msg = useFormatMsg('claims.declaration.main');
  const msgC = useFormatMsg('policies.free_case_request');
  const { declareClaim, getInsuredItems, uploadPolicyFile, uploadClaimFile } =
    useApi();

  const parseObjectToString = () => {
    const formValues = form.getFieldsValue([
      'streetAddress',
      'postalCode',
      'addressLocality',
      'addressCountry',
    ]);
    formValues.addressCountry = msgC(
      `countries.${formValues.addressCountry ?? formData.addressCountry}`,
    );
    return Object.values(formValues).join(', ');
  };

  const callback = devices => {
    setInsuredItems(devices);
    setInitialValues(getInitialValues(user, devices[0], claimContext));
  };

  const { isLoading, setIsLoading } = useCall(
    getInsuredItems(policyId),
    getInsuredItemsMapper,
    callback,
  );

  const onFinish = async newData => {
    const fullDataForm = formatFormData(
      { newData, formData },
      currentStep,
      claimContext,
      partner,
    );
    setFormData(fullDataForm);
    onFinishForm(
      fullDataForm,
      currentStep,
      policyId,
      user,
      nextStep,
      setIsLoading,
      claimContext,
      declareClaim,
      uploadPolicyFile,
      uploadClaimFile,
    );
  };

  const next = () => {
    if (currentStep.label === 'regulation') {
      setIsModalVisible(true);
    } else if (
      partner === 'BMJP' &&
      currentStep.label === 'consent' &&
      claimContext.claimType === 'DATA_RECOVERY'
    ) {
      form.submit();
    } else if (
      currentStep.label === 'consent' &&
      !['LOSS', 'WARRANTY_EXTENSION'].includes(claimContext.claimType)
    ) {
      setIsShowingModalConsent(true);
    } else {
      form.submit();
    }
  };

  const prev = () => {
    currentStep.label === 'consent' ? history.push('/dashboard') : prevStep();
  };

  const incidentCategoryForAntdPlaceholder =
    initialValues?.incidentCategory !== 'undefined'
      ? undefined
      : initialValues?.incidentCategory;

  if (isLoading) return <Spinner />;

  return (
    <Layout>
      <Stepper title={msg('title')} steps={claimSteps} current={indexStep} />
      <Layout.Content
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '2rem',
        }}
      >
        <Form
          onFinish={onFinish}
          name={currentStep.label}
          form={form}
          initialValues={{
            ...initialValues,
            incidentCategory: incidentCategoryForAntdPlaceholder,
          }}
          style={{ width: '100%' }}
        >
          <ClaimDeclarationProvider value={claimContext}>
            <CurrentView />
            <DeliveryAddressModalAccept
              msg={msg}
              handleAccept={() => {
                setIsModalVisible(false);
                form.submit();
              }}
              isModalVisible={isModalVisible}
              handleCancel={() => setIsModalVisible(false)}
              address={parseObjectToString()}
            />
            <ModalConsent
              handleAccept={() => {
                setIsShowingModalConsent(false);
                form.submit();
              }}
              handleCancel={() => setIsShowingModalConsent(false)}
              open={isShowingModalConsent}
              claimType={claimContext.claimType}
            />
          </ClaimDeclarationProvider>
        </Form>
      </Layout.Content>
      <Layout.Footer style={{ backgroundColor: 'transparent' }}>
        {currentStep.label !== 'confirmation' && (
          <Actions next={next} prev={prev} />
        )}
      </Layout.Footer>
    </Layout>
  );
}

export default ClaimDeclaration;
