import configBM from 'config/BM/general';
import configBMUK from 'config/BMUK/general';
import configDI from 'config/DI/general';
import configRF from 'config/RF/general';
import configRFAT from 'config/RFAT/general';
import configBMJP from 'config/BMJP/general';
import configDICH from 'config/DICH/general';
import configSA from 'config/SA/general';
import configMI from 'config/MI/general';
import configYA from 'config/YA/general';
import configFN from 'config/FN/general';
import configMO from 'config/MO/general';
import configRFWE from 'config/RFWE/general';
import configOP from 'config/OP/general';

export default {
  customer: 'Back Market',
  caption: 'Customer Portal',
  description:
    'Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit... There is no one who loves pain itself, who seeks after it and wants to have it, simply because it is pain...',
  BM: configBM,
  DI: configDI,
  BMUK: configBMUK,
  BMJP: configBMJP,
  RF: configRF,
  RFAT: configRFAT,
  YA: configYA,
  MI: configMI,
  FN: configFN,
  SA: configSA,
  DICH: configDICH,
  MO: configMO,
  RFWE: configRFWE,
  OP: configOP,
};
