import React, { useEffect, useState } from 'react';
import { IntlProvider as Provider } from 'react-intl';
import { ConfigProvider } from 'antd';
import { useAppState } from 'providers/AppState';
import flatten from 'helpers/flatObject';
import axios from 'axios';
import { notification } from 'antd';
import { useHistory } from 'react-router';
import { STRAPI_URL } from 'config';

const defaultLocale = 'en';

const IntlProvider = ({ children }) => {
  const {
    locale,
    partner,
    setMessages,
    setIsLoadingLanguage,
    setConfiguration,
  } = useAppState();
  const [intlMessages, setIntlMessages] = useState({});
  const [loadedLocale, setLoadedLocale] = useState(defaultLocale);
  const { push } = useHistory();

  useEffect(() => {
    if (Object.keys(intlMessages).length === 0) {
      setIsLoadingLanguage(true);
    }

    axios
      .get(
        `https://strapi.i-con.app/${STRAPI_URL}${partner}?_locale=${locale.toLowerCase()}`,
      )
      .then(({ data }) => {
        setMessages(data);
        setIntlMessages(data);
        setLoadedLocale(locale);
        setConfiguration(data.configuration || {});
      })
      .catch(() => {
        notification.error({
          message: 'Translations error',
          description: 'Translations could not be loaded, try again later!',
          placement: 'topLeft',
        });
        push('/');
      })
      .finally(() => setIsLoadingLanguage(false));
  }, [locale]);

  return (
    <Provider
      messages={flatten(intlMessages) || {}}
      locale={loadedLocale}
      defaultLocale={defaultLocale}
      onError={() => {}}
    >
      <ConfigProvider locale={loadedLocale}>{children}</ConfigProvider>
    </Provider>
  );
};

export default IntlProvider;
